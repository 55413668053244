import router from '@/router'
import { Toast } from 'vant';
// 从v2.11.2起，SDK 支持了 WebSocket，推荐接入；v2.10.2及以下版本，使用 HTTP
import TIM from 'tim-js-sdk/tim-js-friendship.js'; //如果您的项目不需要关系链功能，请使用 tim-js-sdk

export let tim = null //im实例
export let timIsLogin = false //登录状态  (true：登录，false：登出)

const SDK_APP_ID = Number(process.env.VUE_APP_IM_SDK_APPID)  // 即时通信 IM 应用的 SDKAppID

export const IM_TYPES = {
  JOIN_STATUS_WAIT_APPROVAL: TIM.TYPES.JOIN_STATUS_WAIT_APPROVAL, // 等待管理员同意
  JOIN_STATUS_SUCCESS: TIM.TYPES.JOIN_STATUS_SUCCESS, // 加群成功
  JOIN_STATUS_ALREADY_IN_GROUP: TIM.TYPES.JOIN_STATUS_ALREADY_IN_GROUP, // 已经在群中
  MSG_CUSTOM: TIM.TYPES.MSG_CUSTOM, // 自定义消息
  MSG_TEXT: TIM.TYPES.MSG_TEXT, // 文本消息
  MSG_GRP_TIP: TIM.TYPES.MSG_GRP_TIP, // 群提示消息
  MSG_GRP_SYS_NOTICE: TIM.TYPES.MSG_GRP_SYS_NOTICE, // 群系统通知消息
}

var callBackSocialRoomFun = null  //外部获取监听社交房间信息变更的回调
var callBackSingleChat = null //外部获取监听单聊消息
var callBackGroupChat = null //外部获取监听群聊消息

/**
 * 所有监听函数
 */
// SDK 进入 ready 状态时触发，接入侧监听此事件，然后可调用 SDK 发送消息等 API，使用 SDK 的各项功能
let onSdkReady = function() {
  timIsLogin = true
  tim.on(TIM.EVENT.SDK_NOT_READY, onSdkNotReady);//监听断线
  tim.on(TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived); //监听消息
};

// SDK 进入 not ready 状态时触发，此时接入侧将无法使用 SDK 发送消息等功能。
let onSdkNotReady = function() {
  tim.off(TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived);
  tim.off(TIM.EVENT.SDK_READY, onSdkReady);
  tim.off(TIM.EVENT.SDK_NOT_READY, onSdkNotReady);
};

// SDK 收到推送的单聊、群聊、群提示、群系统通知的新消息，接入侧可通过遍历 event.data 获取消息列表数据并渲染到页面
let onMessageReceived = function(event) {
  // event.data - 存储 Message 对象的数组 - [Message]
  console.log('监听消息-----',event.data);
  let messageList = event.data
  messageList.forEach(item => {
    if(item.type === TIM.TYPES.MSG_GRP_TIP){ // 群提示消息 
      switch (item.payload.operationType) {
        case TIM.TYPES.GRP_TIP_MBR_JOIN: //有人加群
          callBackSocialRoomFun({code: 100000})
          break;
        case TIM.TYPES.GRP_TIP_MBR_QUIT: //有人退群
          callBackSocialRoomFun({code: 100001})
          break;
        case TIM.TYPES.GRP_TIP_MBR_KICKED_OUT: //有群成员被踢出群 (群成员接收)
          callBackSocialRoomFun({code: 100001})
          break;
        default:
          break;
      }
    }else if(item.type === TIM.TYPES.MSG_GRP_SYS_NOTICE){ //群系统通知消息
      switch (item.payload.operationType) {
        case 4: //被踢出群组 (被踢出的用户接收)
          if(router.currentRoute.path == '/groupChat'){
            // 当前页面在群聊页走群聊消息监听
            callBackGroupChat(item) //群聊消息
          }else{
            let timer = setTimeout(() => {
              clearTimeout(timer)
              callBackSocialRoomFun({code: 100001})
            }, 1000);
          }
          break;
        case 5: //群组被解散
          if(router.currentRoute.path == '/groupChat'){
            // 当前页面在群聊页走群聊消息监听
            callBackGroupChat(item) //群聊消息
          }else{
            let timer = setTimeout(() => {
              clearTimeout(timer)
              callBackSocialRoomFun({code: 100007})
            }, 1000);
          }
          break;
        case 255: //管理员自定义通知
          let data = JSON.parse(item.payload.userDefinedField)
          if(item.payload.operatorID == "administrator"){
            if(data.event == 'start_mining'){
              // 开始社交挖矿
              callBackSocialRoomFun({code: 100002, title: data.title})
            }else if(data.event == 'end_mining'){
              // 结束社交挖矿  开始评分
              callBackSocialRoomFun({code: 100003, title: data.title})
            }else if(data.event == 'end_score'){
              // 评分结束 待结算
              callBackSocialRoomFun({code: 100008, title: data.title})
            }else if(data.event == 'end_income'){
              // 已结算 
              callBackSocialRoomFun({code: 100008, title: data.title})
            }
            // let array = data.function_type
            // if(array.length > 0){
            //   array.forEach(element => {
            //     callBackSocialRoomFun({ 
            //       code: element, 
            //       title: data.title, 
            //       route: data.route, 
            //       exp: data.exp,
            //       pop: data.pop,
            //       api: data.api
            //     })
            //   });
            // }
          }
          break;
        default:
          break;
      }
    }else if(item.type === TIM.TYPES.MSG_TEXT && item.conversationID === "C2Cadministrator"){
      let data = JSON.parse(item.payload.text)
      // console.log('C2Cadministrator-----',data);
      switch (data.event) {
        case "add_room_apply": //有人申请加群
          callBackSocialRoomFun({code: 100004, title: data.title})
          break;
        case "add_room_invite": //群主邀请人加入房间
          callBackSocialRoomFun({code: 100006, title: data.title})
          break;
        case "add_room_refuse": //好友拒绝群主邀请
          callBackSocialRoomFun({code: 100010, title: data.title})
          break;
        case "add_room_refuse": //群主拒绝用户申请
          callBackSocialRoomFun({code: 100009, title: data.title})
          break;
        case "tenant": //群主同意用户申请
          callBackSocialRoomFun({code: 100005, title: data.title})
          break;
        default:
          break;
      }
      // let array = data.function_type
      // if(array.length > 0){
      //   array.forEach(element => {
      //     console.log('element-----',element);
      //     callBackSocialRoomFun({ 
      //       code: element, 
      //       title: data.title, 
      //       route: data.route, 
      //       exp: data.exp,
      //       pop: data.pop,
      //       api: data.api
      //     })
      //   });
      // }
    }else if(item.type === TIM.TYPES.MSG_TEXT && item.conversationType == "C2C"){
      //单聊消息
      callBackSingleChat(item)
    }else if(item.type === TIM.TYPES.MSG_TEXT && item.conversationType == "GROUP"){
      //群聊消息
      callBackGroupChat(item)
    }
  });
};

// 外部通过回调获取社交房间变更信息
export function socialRoom(callback) {
  callBackSocialRoomFun = callback
}

// 外部通过回调获取单聊消息
export function singleChat(callback) {
  callBackSingleChat = callback
}

// 外部通过回调获取群聊消息
export function groupChat(callback) {
  callBackGroupChat = callback
}

//初始化im实例
export function initIM() {
  let options = {
    SDKAppID: SDK_APP_ID
  };
  // 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
  tim = TIM.create(options);

  // tim.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
  tim.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用
}

// 登录im
export function loginIM(userID, userSig){
  let promise = tim.login({userID, userSig});
  promise.then(function(imResponse) {
    console.log('登录成功----',imResponse.data); // 登录成功
    tim.on(TIM.EVENT.SDK_READY, onSdkReady); // 监听ready事件
    if (imResponse.data.repeatLogin === true) {
      // 标识帐号已登录，本次登录操作为重复登录。v2.5.1 起支持
      console.log(imResponse.data.errorInfo);
    }
  }).catch(function(imError) {
    console.warn('login error:', imError); // 登录失败的相关信息
    let toast = Toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
      message: imError.message,
    });
  });
}

//注销im实例
export function destroyIM(){
  let promise = tim.logout();
  promise.then((imResponse) => {
    tim.destroy(); // 销毁实例
    tim = null
    timIsLogin = false
    callBackSocialRoomFun = null
    callBackSingleChat = null
    callBackGroupChat = null
  }).catch((imError) => {
    console.warn('logout error:', imError);
  });
}

// 更新个人资料
export function updateMyProfile(params) {
  const { nickname, avatar } = params
  // 修改个人标配资料
  return tim.updateMyProfile({ // allowType 当被加人加好友时
    nick: nickname,
    avatar,
    allowType: TIM.TYPES.ALLOW_TYPE_NEED_CONFIRM, // 需要验证
    messageSettings: 0  // 接收消息
  });
}

// 添加好友
export function addFriend(userId) {
  return tim.addFriend({
    to: userId,
    source: 'AddSource_Type_Web',
    type: TIM.TYPES.SNS_ADD_TYPE_BOTH,//加好友方式(双向加好友方式)
  });
}

//获取好友申请的未读数
export async function getFriendAddCount() {
  try {
    let imResponse = await tim.getFriendApplicationList();
    // friendApplicationList: 好友申请列表; unreadCount: 好友申请的未读数
    let { unreadCount } = imResponse.data;
    console.log('好友申请的未读数------',imResponse.data);
    return unreadCount
  } catch (error) {
    return error
  }
}

// 创建社交房间(群聊)
export function createGroup(name, maxMemberNum) {
  // 社交群
  return tim.createGroup({
    type: TIM.TYPES.GRP_PUBLIC,//陌生人社交群
    name,//群聊名称
    maxMemberNum: Number(maxMemberNum),//最大人数
    joinOption: TIM.TYPES.JOIN_OPTIONS_NEED_PERMISSION,
    groupCustomField: [{key: 'GroupMaxNum', value: maxMemberNum}]
  });
}

// 创建并发送消息
export function createMessage(params) {
  const {type, id, text} = params
  let conversationType = null
  switch (type) {
    case 'C2C': // 单聊
      conversationType = TIM.TYPES.CONV_C2C
      break;
    case 'GROUP': // 群聊
      conversationType = TIM.TYPES.CONV_GROUP
      break;
    default:
      break;
  }
  // 1. 创建消息实例
  let message = tim.createTextMessage({
    to: id,
    conversationType,
    payload: {
      text
    },
    // v2.20.0起支持C2C消息已读回执功能，如果您发消息需要已读回执，需购买旗舰版套餐，并且创建消息时将 needReadReceipt 设置为 true
    needReadReceipt: true
  });

  // 2. 发送消息
  return tim.sendMessage(message);
}

//  获取群详细资料
export function getGroupProfile(groupID) {
  return tim.getGroupProfile({ groupID });
}

// 群主解散社交房间
export function dismissGroup(groupID) {
  // 解散群组
  return tim.dismissGroup(groupID);
}

// 退出社交房间
export function quitGroup(groupID) {
  // 退出群组
  return tim.quitGroup(groupID);
}

// 群主踢人
export function deleteGroupMember(groupID, userId) {
  // 删除群成员
  return tim.deleteGroupMember({groupID, userIDList: [userId], reason: '群主踢人'});
}

//拉取历史消息(单聊/群聊)
export function getMessageList(params) {
  const { conversationID, nextReqMessageID } = params
  if(!nextReqMessageID){
    params = { conversationID }
  }
  return tim.getMessageList(params);
}

// 同意好友申请
export function acceptFriendApplication(userID) {
  return tim.acceptFriendApplication({
    userID,
    type: TIM.TYPES.SNS_APPLICATION_AGREE_AND_ADD
  });
}

// 获取所有的单聊会话未读总数
export async function getC2CunreadCount() {
  let count = 0
  // 获取单聊会话列表
  try {
    let imResponse = await tim.getConversationList({ type: TIM.TYPES.CONV_C2C });
    let conversationList = imResponse.data.conversationList; // 会话列表
    console.log('单聊会话列表------',conversationList);

    // 遍历会话列表 
    conversationList.forEach(element => {
      if(element.conversationID != 'C2Cadministrator'){ // 管理员的单聊不累加
        // 累加unreadCount得到总数
        count += element.unreadCount
      }
    });
    console.log('单聊会话未读总数------',count);
    return count
  } catch (error) {
    return error
  }
}

// 上报好友申请已读
export function setFriendApplicationRead() {
  tim.setFriendApplicationRead();
}

// 将某会话下所有未读消息已读上报
export function setMessageRead(conversationID) {
  // 已读上报成功的话，指定 ID 的会话的 unreadCount 属性值被置为0
  tim.setMessageRead({ conversationID });//C2Cexample
}
/* 马来西亚  Malay */
export default {
     '一起社交赚钱': 'Jom Sosial dan Dapatkannya',
     '重连...': 'Menyambung semula...',
     '收件箱': 'Peti Surat',
     '聊天': 'Sembang',
     '消息/聊天': 'Mesej',
     '消息 - 未读': 'Mesej - Belum dibaca',
     '没有更多了': 'Tidak ada lagi',
     '暂无昵称': 'Tidak mempunyai nama panggilan ',
     '排行': 'Kedudukkan',
     '在此输入...': 'Taip di sini...',
     '连接中...': 'Menyambung  ...',
     '发送消息不能为空': 'Mesej ini tidak boleh kosong',
     '连接钱包': 'Menyambung ke dompet',
     '选择要连接的钱包': 'Pilih dompet untuk disambung',
     '钱包': 'Dompet',
     '好友': 'Rakan',
     '朋友': 'Rakan',
     '加载中...': 'Memuatkan...',
     '添加好友': 'Tambah kawan',
     '请输入搜索关键词': 'Sila Masukkan Kata Kunci Carian',
     '推荐玩家': 'Pemain yang disyorkan',
     '刷新': 'Muatkan semula',
     '请求': 'Permintaan',
     '好友请求': 'Permintaan rakan',
     '积分': 'Mata',
     'NFT收藏': 'Koleksi NFT',
     '探索距离': 'Jarak',
     '步数': 'Langkah',
     '心率': 'BPM Jantung',
     '心率差': 'perbezaan kadar denyutan jantung',
     '卡路里': 'Kalori',
     '时长': 'Tempoh masa',
     '耐用度': 'Ketahanan',
     '开始运动': 'Mulakan',
     '结束运动': 'Tamatkan senaman',
     '恢复耐久': 'Pulihkan ketahanan',
     '日期预设': 'Tarikh praset',
     '月': 'Bulan ',
     '日': 'Hari',
     '户外跑步总记录': 'Laporan larian luar',
     '查看活动报告': 'Lihat laporan aktiviti',
     '交易市场': 'Pasaran',
     '社交挖矿': 'Sosial',
     '选择年月': 'Pilih tahun dan bulan',
     '修复中...': 'Membaiki...',
     '修复成功': 'Telah berjaya dibaiki',
     '修复失败': 'Pembaikan gagal',
     '市场': 'Pasaran',
     '探索': 'Menemui',
     '热门': 'Popular',
     '拍卖': 'lelong',
     '总共': 'Jumlah',
     '暂无': 'Tiada',
     '购买': 'Beli',
     '授权': 'Keizinan memberi',
     '余额不足': 'Baki tidak mencukupi',
     '购买中...': 'Membeli...',
     '购买成功': 'Telah berjaya dibeli',
     '购买失败': 'Pembelian gagal',
     '授权中...': 'keizinan memberi...',
     '授权成功': 'Keizinan memberi berjaya',
     '授权失败': 'Keizinan memberi gagal',
     '我的NFT收藏': 'NFT saya',
     'NFT角色信息': 'Info NFT',
     '取消挂卖': 'Batalkan penyenaraian',
     '挂卖': 'Jual',
     '升级中...': 'Kenaikan tahap sedang diproses...',
     '升级': 'Naik tahap',
     '修复耐久性': 'Pulihkan ketahanan',
     '信息': 'Mesej',
     '成长': 'Naik taraf',
     '最高等级': 'Tahap maksimum',
     '请输入挂卖价格': 'Sila Masukkan Harga Jualan',
     '确定': 'Sahkan',
     '取消': 'Batal',
     '价格': 'Harga',
     '请输入价格': 'Sila Masukkan Harga  ',
     '取消中...': 'Pembatalan sedang diproses...',
     '取消成功': 'Pembatalan Telah Berjaya',
     '取消失败': 'Pembatalan Gagal',
     '价格需要大于0': 'Harga Perlu Lebih Besar Daripada 0',
     '挂卖中...': 'Penyenaraian sedang diproses...',
     '挂卖成功': 'Berjaya disenaraikan',
     '挂卖失败': 'Penyenaraian gagal',
     '这张卡片不需要修复': 'NFT ini tidak perlu dibaiki',
     '修复成功,等待交易查询中': 'Telah berjaya dibaiki, sila tunggu transaksi',
     '升级失败': 'Naik taraf gagal',
     '我的简历': 'Profil Saya',
     '地址': 'Alamat',
     '我的钱包余额': 'Baki dompet saya',
     '请输入口令': 'Sila masukkan kata laluan',
     '当地语言': 'Bahasa tempatan',
     '评分列表': 'Senarai kadaran',
     '购买评分': 'Pembelian kadaran',
     '密码不能为空': 'Kata laluan tidak boleh kosong',
     '复制成功': 'Disalin',
     '不支持复制': 'Tidak menyokong salinan',
     '购买成功,等待交易查询中': 'Pembelian Berjaya, Sila Tunggu Transaksi Untuk Disahkan',
     '主菜单': 'Menu Utama',
     '首页': 'Halaman Utama',
     '任务': 'Misi',
     '排行榜': 'Kedudukkan',
     '开启运动模式': 'pindah dan dapatkan',
     '开启社交模式': 'Sosial dan dapatkan',
     '聊天室': 'Bilik Sosial',
     '个人简介': 'Profil Saya',
     '报告': 'Laporan',
     '时间': 'Masa',
     '前三名玩家': '3 Pemain Tangga Teratas',
     '暂无排名': 'Tidak mempunyai kedudukkan',
     '玩家排行': 'Kedudukkan pemain',
     '选择年月日': 'Pilih Tahun Bulan Hari',
     '运动': 'Senaman',
     '社交': 'Sosial',
     '总分': 'Jumlah mata',
     '持续': 'Teruskan',
     '玩家': 'Pemain',
     '等级': 'Tahap',
     '成本': 'Kos',
     '开始时间': 'Masa Mula',
     '至': 'Sehingga',
     '结束时间': 'Masa Tamat',
     '收益': 'Pendapatan',
     '我的收益报告': 'Laporan Keuntungan Saya',
     '奖励': 'Ganjaran',
     '我的奖励报告': 'Laporan Ganjaran Saya',
     '我的任务报告': 'Laporan Misi Saya',
     '我的NFT报告': 'Laporan NFT Saya',
     '开始时间要小于结束时间': 'Masa Mula Mesti Kurang Daripada Masa Tamat',
     '结束时间要大于开始时间': 'Masa Tamat Mesti Lebih Besar Daripada Masa Mula ',
     '社交聊天': 'Sembang Sosial',
     '聊天室名称': 'Nama Bilik Sosial',
     '人数': 'Bilangan orang',
     '创建': 'Cipta',
     '房间名不能为空': 'Nama Bilik Tidak Boleh Kosong',
     '聊天室邀请': 'Jemputan Bilik Sosial',
     '邀请': 'Jemput',
     '暂无名称': 'Tiada Nama ',
     '距离': 'Jarak',
     '准备': 'Sedia',
     '邀请朋友': 'Jemput Rakan',
     '米': 'Meter',
     '暂无房间id': 'Tiada ID bilik',
     '我的聊天室': 'Bilik Sosial Saya',
     '购买钥匙': 'Pembelian Kunci',
     '准备中': 'Menyediakan',
     '进行中': 'Dalam proses',
     '待评价': 'Kadaran yang belum selesai',
     '待结算': 'Ganjaran yang belum selesai',
     '已结束': 'Selesai',
     '暂未创建或加入聊天室': 'Tiada Bilik Sosial Telah Dicipta Atau Sertai',
     '推荐聊天室': 'Bilik Sosial yang disyorkan',
     '头像': 'Gambar profil',
     '这个聊天室没有名称': 'Bilik Sosiall Ini Tidak Ada Nama',
     '申请': 'Mohon',
     '已加入聊天室': 'Sudah Menyertai Bilik Sosial',
     '等待其他玩家评价': 'Menunggu pemain untuk menilai',
     '您还没有创建房间': 'Anda Belum Mencipta Bilik Sosial',
     '开始挖矿': 'Mulakan',
     '退出房间': 'Tinggalkan Bilik',
     '复制链接': 'Salin Pautan Jemputan',
     '发送邀请': 'Hantar Jemputan',
     '提示': 'Peringatan',
     '请确认是否退出聊天室': 'Sila Sahkan Sebelum Meninggalkan Bilik Sosial',
     '请输入邀请链接': 'Sila Masukkan Pautan Jemputan',
     '加入聊天室': 'Sertai',
     '在挖矿中': 'Dalam proses Sosial dan Dapatkan',
     '定时进程': 'Kira Detik',
     '完成挖矿': 'Selesaikan',
     '停止聊天室': 'Hentikan bilik sosial',
     '在线玩家': 'Pemain dalam talian',
     '请确认是否停止聊天，此操作将会退出聊天室并且不会获得收益': 'Sila Sahkan Untuk Menghentikan Bilik Sosial, Operasi Ini Akan Meninggalkan Bilik Sosial Dan Tidak Akan Mendapat Ganjaran.',
     '社交聊天室': 'Bilik Sosial',
     '创建聊天室': 'Cipta Bilik',
     '邀请链接': 'Pautan jemputan',
     '有新的请求，请在请求弹窗中查看': 'Terdapat Permintaan Baharu, Sila Semak Dalam Popup Timbul Permintaan',
     '有新的邀请，请在邀请弹窗中查看': 'Ada Jemputan Baharu, Sila Semak Dalam Popup Jemputan',
     '房主已拒绝': 'Pemilik Bilik Telah Menolak Permintaan Anda',
     '用户拒绝加入': 'Pemain Telah Ditolak Untuk Menyertai',
     '挖矿收益': 'Ganjaran Sosial Dan Dapatkan ',
     '我的评分': 'Kedudukan saya',
     '奖励分配': 'Peruntukan ganjaran',
     '评分': 'Kedudukkan',
     '社交评分': 'Kedudukkan sosial',
     '提交': 'Hantar',
     "暂无计划":"Tiada rancangan lagi",
     "活动报告":"Laporan aktiviti",
     "分钟":"Minit",
     "每日":"Setiap Hari",
     "每月":"Setiap Bulan",
     "获得":"Dapatkan",
     "总支出":"Jumlah perbelanjaan",
     "点 击 关 闭":"Klik untuk tutup",
     "朋友帮助请求":"Permintaan bantuan rakan",
     "发送帮助":"Hantar bantuan",
     "推荐任务":"Misi yang disyorkan",
     "描述":"Penerangan",
     "接受":"Terima",
     "待完成":"Belum selesai",
     "特别活动":"Aktiviti istimewa",
     "特别":"Istimewa",
     '此操作会将房间解散，并且不会退还钥匙': 'Tindakan Ini Akan Membubarkan Bilik Dan Kunci Tidak Akan Dipulangkan',
     '运动收益': 'Ganjaran Sukan',
     '社交收益': 'Ganjaran Sosial',
     '累计时长(分钟)': 'Masa kumulatif (minit)',
     '实时运动': 'Pemasa',
     '领取': 'Tuntutan',
     '交易成功后等级 + 1': 'Tahap + 1 selepas transaksi berjaya',
     '信息更新中...': 'Maklumat Sedang Kemas Kini...',
     '连接钱包中...': 'Menyambung dompet...',
     '解散房间': 'Bilik bubar',
     '未结算': 'Tidak selesai',
     '已结算': 'Selesai',
     '日期选择': 'Pilihan Tarikh',
     '未领取': 'Tidak diterima',
     '已领取': 'Dituntut',
     '已升级的nft数量': 'Kuantiti NFT ditingkatkan',
     '请选择修复次数':'Sila pilih bilangan pembaikan',
     '暂无nft可领取':'Tiada NFT tersedia untuk koleksi',
     '暂无团队收益可领取':'Pada masa ini tiada ganjaran pasukan untuk diterima',
     '团队收益':'Ganjaran Pasukan',
     '当前修复分数': 'Ketahanan Pembaikan Semasa',
     '请输入密码': 'Sila Masukkan Kata Laluan',
     '设置密码': 'Tetapkan kata laluan',
     '密码': 'Kata laluan',
     '再次确认': 'Sahkan Kata Laluan',
     '请再次输入密码': 'Sila Masukkan Kata Laluan Semula',
     '两次输入的密码不一致': 'Kata laluan tidak sepadan',
     '未激活': 'Tidak aktif',
     '已激活': 'Diaktifkan',
     '成功': 'Berjaya',
     '失败': 'gagal',
     '请确认是否将此用户请离房间': 'Sila sahkan jika teruskan untuk mengalih keluar pengguna ini daripada bilik',
     '收藏奖励': 'Hadiah koleksi',
     '教程': 'Tutorial',
     '请选择类型': 'Sila pilih jenis',
     '评分(人气)': 'Penilaian',
     '日榜': 'Hari',
     '月榜': 'Bulan',
     '总榜': 'Jumlah',
     '升级进度': 'kemajuan naik taraf',
     '暂无收益可领取': 'Tiada pendapatan boleh diklaim sementara',
     '价格需要大于5000': 'Harga Perlu Lebih Besar Daripada 5000',
     '团队NFT升级奖励': 'Pendapatan Naik Taraf NFT',
     '名称': 'Nama',
     '钱包地址': 'Alamat Dompet',
     '设置昵称': 'Tetapkan Nama Alih',
     '昵称': 'nama gelaran',
     '请输入昵称': 'Sila masukkan gelaran',
     '昵称不能为空': 'Nama panggilan tidak boleh kosong',
     '请先选择头像': 'Sila pilih avatar dahulu',
     '设置个人信息': 'Tetapkan maklumat peribadi',
     '数量': 'kuantiti',
     '选择钥匙数量': 'Pilih bilangan kekunci',
     '房间人数需要大于2,才能正常退出': 'Bilangan orang di bilik perlu lebih besar daripada 2 untuk keluar secara biasa',
     '价格需要大于50000': 'Harganya perlu lebih dari 50000',
     '是否确认绑定': 'Adakah anda pasti untuk mengikatkan atasan?',
     '上级名称': 'Nama Atasan：',
     '上级邀请码': 'Kod Jemputan Atasan：',
     '上级地址': 'Alamat Atasan：',
     '请通过新的邀请链接进入': 'Sila masukkan melalui pautan undangan baru',
     '群组/聊天': '群组/聊天',
     '此卡已是最高等级': 'NFT ini sudah pada tahap tertinggi',
     '领取中': 'Sedang menuntut',
     '不可领取': 'Tidak boleh dituntut',
     '领取成功': 'Berjaya dituntut',
     '已卖出': 'Terjual',
     '已升级': 'Dikemas kini',
     '请先选择房间': 'Sila pilih bilik terlebih dahulu',
     '网络不好,请稍后再试': 'Sambungan rangkaian lemah, sila cuba lagi kemudian',
     '购买钥匙授权': 'Membeli kebenaran kunci',
     '购买评分授权': 'Membeli kebenaran penilaian',
     '修复耐久授权': 'Kebenaran pembaikan daya tahan',
     '升级授权': 'Kebenaran peningkatan',
     '挂卖补偿奖励': 'Pampasan ganjaran penjualan',
     '进入聊天室': 'Masuk ke bilik perbualan sosial',
     '*请在1小时结束社交后进行评分': '*Sila beri penilaian selepas 1 jam berinteraksi sosial',
     '确认分享': 'Sahkan perkongsian',
     '账号未激活，是否分享邀请链接?': 'Akaun belum diaktifkan, adakah anda ingin berkongsi pautan jemputan?',
     '绑定上级中,链上交易需要时间,请耐心等待几分钟再去操作...': 'Sedang mengikat ke atas, transaksi di atas rangkaian mengambil masa, sila tunggu beberapa minit sebelum meneruskan pengaktifan...',
     '绑定成功': 'Berjaya diikatkan',
     '绑定失败': 'Gagal diikatkan',
     '链上已经绑定上级,交易确认中,请稍后再试': 'Atasan sudah diikat di atas rangkaian, pengesahan transaksi sedang berlangsung, sila cuba lagi nanti',
     '登录已过期,请重新登录': 'Sesi log masuk tamat, sila log masuk semula.',
     '确认购买': 'Sahkan Pembelian',
     '购买钥匙当次所需数量为:': 'Kuantiti yang diperlukan untuk membeli kunci:\n',
     '购买评分当次所需数量为:': 'Kuantiti yang diperlukan untuk membeli penarafan:\n',
     '确认修复': 'Sahkan Baiki',
     '修复耐久当次所需数量为:': 'Kuantiti yang diperlukan untuk memperbaiki daya tahan:\n',
     '确认升级': 'Sahkan Naik Taraf ',
     '升级NFT当次所需数量为:': 'Kuantiti yang diperlukan untuk meningkat taraf NFT adalah\n',
}
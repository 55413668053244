<template>
  <!-- 手表给建模场景的二级页面项目 -->
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { login, userInfo, userBind, getUserSign, getUserHadParent } from './api/api'
import { mapMutations, mapState } from 'vuex'
import { initIM, loginIM, destroyIM } from '@/utils/tim.js'
export default {
  data(){
    return {
      superiorCode: '',
      timer: null,
    }
  },
  computed: {
    ...mapState([
      'web3','toolContract',
    ]),
  },
  beforeCreate(){
    window.localStorage.removeItem('defaultAddress')
    window.localStorage.removeItem('Token')
    window.localStorage.removeItem('userInfo')
  },
  mounted() {
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e)); // 浏览器刷新事件
  },
  destroyed() {
    window.removeEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
  },
  created(){
    this.waitForGlobal();
  },
  methods: {
    ...mapMutations([
      'setWeb3',
      'setNftContract',
      'setMarketContract',
      'setDefaultAddress',
      'setToolContract',
      // 'setPledgeContract',
      'setSolarixContract',
      'setIonzContract',
      // 'setBusdContract',
      // 'setIonzBnbPledgeContract',
      // 'setSolarixBnbPledgeContract',
      // 'setIonzBnbContract',
      // 'setSolarixBnbContract',
      'setBitneyContract',
      'setNewMarketContract',
      'setNewNftContract',
      'setNameNewNftContract',
      'setWithdrawContract',
      'setPlaceOrderContract',
    ]),
    getSign(userId){
      getUserSign().then(res => {
        if(res.code == 0){
          loginIM(userId, res.data)
        }
      })
    },
    beforeunloadHandler(e) {
      console.log('销毁im-----');
      destroyIM()
    },
    setBind(defaultAddress, Token){
      let toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t('信息更新中...'),//信息更新中...
      });
      this.toolContract.methods.getReferrer(defaultAddress).call().then(res => {
        if(res != '0x0000000000000000000000000000000000000000'){
          userBind({}, Token).then(res => {
            console.log('更新链上是否已绑定上级----',res)
            toast.clear();
          }).catch(err => {
            toast.clear();
          })
        }else{
          toast.clear();
        }
      }).catch(err => {
        toast.clear();
      })
    },
    getQueryVariable(variable='invite') {
      var query = window.location.href;
      if(window.frameElement){
        query = window.frameElement.baseURI
      }
      query = decodeURIComponent(query)
      var vars = query.split("?");
      for (var i=0;i<vars.length;i++) {
        var pair = vars[i].split("/")[0].split("=")
        if(pair[0] == variable){
          this.superiorCode = pair[1]
          return pair[1];
        }
      }
      return false
    },
    userLogin(defaultAddress, toast){
      login({
        invite: this.superiorCode,
        username: defaultAddress,
        password: this.$md5( this.$md5(defaultAddress + 'nft') + 'nft' ),
      }).then(res => {
        if(res.code == 0){
          window.localStorage.setItem('Token', res.data.Token)
          window.localStorage.setItem('defaultAddress', defaultAddress)
          userInfo({}, res.data.Token).then(resu => {
            if(resu.code == 0){
              toast.clear();
              initIM()
              window.localStorage.setItem('userInfo', JSON.stringify(resu.data) )
              window.localStorage.setItem('langue', resu.data.lang )
              this.$i18n.locale = resu.data.lang
              // this.setBind(defaultAddress, res.data.Token)
              this.getSign(resu.data.id)
            }
          })
        }else{
          toast.clear();
          this.$toast({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: res.msg,
          });
        }
      })
    },
    judgeSuperior(defaultAddress, toast){
      this.getQueryVariable();
      getUserHadParent({invite: this.superiorCode, username: defaultAddress}).then(res => {
        if(res.code == 0){ // 根据邀请码查询地址信息
          let parentName = res.data.nickname ? res.data.nickname : this.$t('暂无昵称')
          let parentInvite = res.data.invite
          let parentAddress = res.data.username.slice(0, 6) + "****" + res.data.username.slice(-6) 
          if(res.data.had_parent == 1){ // 绑定过上级 (老用户) 直接进入
            this.userLogin(defaultAddress, toast);
          }else{ // 没绑定过上级 (新用户) 提示确认取消按钮弹窗
            toast.clear();
            this.$dialog.confirm({
              message: this.$t('上级名称') + parentName + '\n' + this.$t('上级邀请码') + parentInvite + '\n' + this.$t('上级地址') + parentAddress + '\n' + this.$t('是否确认绑定'),
              confirmButtonText: this.$t('确定'),
              cancelButtonText: this.$t('取消'),
            }).then(() => {
              let toastLoading = this.$toast.loading({
                  duration: 0, // 持续展示 toast
                  forbidClick: true,
                  message: this.$t('连接钱包中...'),
              });
              this.userLogin(defaultAddress, toastLoading); // 确认之后登录
            }).catch(() => {
              this.$toast({
                duration: 0,
                forbidClick: true,
                message: this.$t('请通过新的邀请链接进入')
              });
            });
          }
        }else{
          toast.clear();
          this.$toast({
            duration: 0,
            forbidClick: true,
            message: res.msg
          });
        }
      })
    },
    //链接钱包
    waitForGlobal: async function () {
      window.clearTimeout(this.timer);
      // 1. check variable, 检查web3是否已经加载
      let toast = this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: this.$t('连接钱包中...'),
      });
      var web3Provider = null
      if (window.ethereum && window.ethereum.isMetaMask) { 
          try {
            // 请求用户受权
            // await window.ethereum.enable();
            await window.ethereum.request({method: "eth_requestAccounts"});
          } catch (error) {
            // 用户不受权时
            console.error("User denied account access")
          }
        web3Provider = window.ethereum;
      } else if (window.web3) {   // 老版 MetaMask Legacy dapp browsers...
        web3Provider = window.web3.currentProvider;
      } else if (window.parent.ethereum) {   // 在iframe中，要获取父级的window对象
        web3Provider = window.parent.ethereum;
      } else {
        // web3Provider = new this.Web3.providers.HttpProvider('http://localhost:8545');
        this.timer = setTimeout(
          this.waitForGlobal
        ,100);
        return;
      }
      let web3 = new this.Web3(web3Provider);

      this.setWeb3(web3)
      this.setMarketContract(web3)
      this.setNftContract(web3)
      this.setToolContract(web3)
      // this.setPledgeContract(web3)
      this.setSolarixContract(web3)
      this.setIonzContract(web3)
      // this.setBusdContract(web3)
      // this.setIonzBnbPledgeContract(web3)
      // this.setSolarixBnbPledgeContract(web3)
      // this.setIonzBnbContract(web3)
      // this.setSolarixBnbContract(web3)
      this.setBitneyContract(web3)
      this.setNewMarketContract(web3)
      this.setNewNftContract(web3)
      this.setNameNewNftContract(web3)
      this.setWithdrawContract(web3)
      this.setPlaceOrderContract(web3)
      
      web3.eth.getAccounts( (error, result) => {
        if (!error){
            //受权成功后result能正常获取到帐号了
            this.setDefaultAddress(result[0])
            if( localStorage.getItem('defaultAddress') != result[0] ){
              this.judgeSuperior(result[0], toast)
            }
            // toast.clear();
            window.clearTimeout(this.timer);
        }
      });
    },
  }
}
</script>

<style lang="less">
@import "./assets/css/font.css";
body {
  margin: 0px;
}
div{
  box-sizing: border-box;
}
.pointer{
  cursor: pointer;
}

@media screen and (min-width: 480px){
  //在这里写非小屏幕设备的样式  -- 非手机
  body {
    margin: auto !important;
    max-width: 430px;
    // max-height: 700px;
    // overflow-y: scroll;

    scrollbar-width: none;/* Firefox */
    -ms-overflow-style: none;/* IE 10 */
  }
  body::-webkit-scrollbar{
    display: none;
  }
  #app{
    // max-height: 700px;
    // overflow-y: scroll;

    scrollbar-width: none;/* Firefox */
    -ms-overflow-style: none;/* IE 10 */
  }
  #app::-webkit-scrollbar{
    display: none;
  }
}
.vn-italic{
    font-family: 'vn-italic' !important;
  }
  .vn-bold-italic{
    font-family: 'vn-bold-italic' !important;
  }
</style>

/* 越南 */
export default {
     '一起社交赚钱': 'Cùng nhau giao lưu để kiếm tiền',
     '重连...': 'Kết nối lại ...',
     '收件箱': 'Hộp thư đến',
     '聊天': 'Trò chuyện ',
     '消息/聊天': 'Tin nhắn',
     '消息 - 未读': 'Tin nhắn / Chưa đọc ',
     '没有更多了': 'Hết rồi',
     '暂无昵称': 'Hiện không có tên hiệu',
     '排行': 'Xếp hạng',
     '在此输入...': 'Nhập vào đây...',
     '连接中...': 'Đang kết nối ...',
     '发送消息不能为空': 'Tin nhắn gửi không được để trống',
     '连接钱包': 'Kết nối ví ',
     '选择要连接的钱包': 'Chọn ví để kết nối',
     '钱包': 'Ví',
     '好友': 'Bạn',
     '朋友': 'Bạn',
     '加载中...': 'Đang tải...',
     '添加好友': 'Thêm bạn ',
     '请输入搜索关键词': 'Vui lòng nhập từ khóa tìm kiếm',
     '推荐玩家': 'Người chơi được đề xuất',
     '刷新': 'Làm mới ',
     '请求': 'Yêu cầu ',
     '好友请求': 'Yêu cầu kết bạn',
     '积分': 'Điểm ',
     'NFT收藏': 'Thu thập NFT',
     '探索距离': ' khoảng cách khám phá ',
     '步数': 'Số bước',
     '心率': 'Nhịp tim ',
     '心率差': 'nhịp tim chênh lệch',
     '卡路里': 'calo',
     '时长': 'Thời lượng',
     '耐用度': 'Độ bền ',
     '开始运动': 'Bắt đầu',
     '结束运动': 'Kết thúc vận động ',
     '恢复耐久': 'Khôi phục sức bền',
     '日期预设': 'Đặt trước thời gian ',
     '月': 'Tháng ',
     '日': 'Ngày ',
     '户外跑步总记录': 'Tổng lịch sử chạy ngoài trời ',
     '查看活动报告': 'Tra cứu báo cáo hoạt động ',
     '交易市场': 'Thị trường',
     '社交挖矿': 'xã hội',
     '选择年月': 'Chọn năm tháng ',
     '修复中...': 'Đang khôi phục ...',
     '修复成功': 'Khôi phục thành công ',
     '修复失败': 'Khôi phục thất bại ',
     '市场': 'Thị trường',
     '探索': 'Khám phá ',
     '热门': 'Phổ biến ',
     '拍卖': 'Đấu giá',
     '总共': 'Tổng cộng ',
     '暂无': ' Không có',
     '购买': 'Mua ',
     '授权': 'Ủy quyền',
     '余额不足': 'Số dư không đủ ',
     '购买中...': 'Đang mua ...',
     '购买成功': 'Mua thành công ',
     '购买失败': 'Mua thất bại ',
     '授权中...': 'Đang uỷ quyền ...',
     '授权成功': 'Uỷ quyền thành công ',
     '授权失败': 'Uỷ quyền thất bại ',
     '我的NFT收藏': 'NFT của tôi ',
     'NFT角色信息': 'Thông tin vai trò NFT',
     '取消挂卖': 'Huỷ bỏ lệnh bán',
     '挂卖': 'Đặt lệnh bán ',
     '升级中...': 'Đang nâng cấp ...',
     '升级': 'Nâng cấp ',
     '修复耐久性': 'Tính Khôi phục độ bền ',
     '信息': 'Tin tức',
     '成长': 'Trưởng thành ',
     '最高等级': 'Cấp cao nhất ',
     '请输入挂卖价格': 'Vui lòng nhập giá đặt bán ',
     '确定': 'Xác nhận ',
     '取消': 'Huỷ bỏ',
     '价格': 'Giá ',
     '请输入价格': 'Vui lòng nhập giá',
     '取消中...': 'Đang huỷ bỏ ...',
     '取消成功': 'Huỷ thành công ',
     '取消失败': 'Huỷ thất bại ',
     '价格需要大于0': 'Giá phải lớn hơn 0',
     '挂卖中...': 'Đang đặt lệnh bán...',
     '挂卖成功': 'Đặt lệnh thành công ',
     '挂卖失败': 'Đặt lệnh thất bại ',
     '这张卡片不需要修复': 'Thẻ này không cần khôi phục ',
     '修复成功,等待交易查询中': 'Khôi phục thành công chờ tra cứu giao dịch',
     '升级失败': 'Nâng cấp thất bại ',
     '我的简历': 'Hồ sơ của tôi ',
     '地址': 'Địa chỉ ',
     '我的钱包余额': 'Số dư ví của tôi ',
     '请输入口令': 'Vui lòng nhập mật khẩu ',
     '当地语言': 'Ngôn ngữ bản địa ',
     '评分列表': 'Danh sách xếp hạng ',
     '购买评分': 'Xếp hạng mua hàng',
     '密码不能为空': 'Mật khẩu không được để trống ',
     '复制成功': 'Sao chép thành công ',
     '不支持复制': 'Không hỗ trợ sao chép ',
     '购买成功,等待交易查询中': 'Mua thành công chờ tra cứu giao dịch',
     '主菜单': 'Menu chính',
     '首页': 'Trang chủ ',
     '任务': 'Nhiệm vụ ',
     '排行榜': 'Bảng xếp hạng ',
     '开启运动模式': ' Khởi động chế độ vận động ',
     '开启社交模式': 'Khởi động chế độ cộng đồng ',
     '聊天室': 'Phòng trò chuyện',
     '个人简介': 'Hồ sơ cá nhân',
     '报告': 'Báo cáo ',
     '时间': 'Thời gian ',
     '前三名玩家': 'Người chơi Top 3 ',
     '暂无排名': 'Không có xếp hạng ',
     '玩家排行': 'Xếp hạng người chơi ',
     '选择年月日': 'Chọn ngày tháng năm ',
     '运动': 'Vận động ',
     '社交': 'Cộng đồng',
     '总分': 'Tổng điểm ',
     '持续': 'Tiếp tục ',
     '玩家': 'Người chơi',
     '等级': 'Cấp độ ',
     '成本': 'Giá thành ',
     '开始时间': 'Thời gian bắt đầu ',
     '至': 'Đến ',
     '结束时间': ' Thời gian kết thúc',
     '收益': 'Thu nhập ',
     '我的收益报告': 'Báo cáo thu nhập của tôi ',
     '奖励': 'Thưởng ',
     '我的奖励报告': 'Báo cáo thưởng của tôi ',
     '我的任务报告': 'Báo cáo nhiệm vụ của tôi ',
     '我的NFT报告': 'Báo cáo NFT của tôi ',
     '开始时间要小于结束时间': 'Thời gian bắt đầu phải ít hơn thời gian kết thúc',
     '结束时间要大于开始时间': 'Thời gian kết thúc phải nhiều hơn thời gian bắt đầu ',
     '社交聊天': 'Trò chuyện xã hội',
     '聊天室名称': 'Tên phòng trò chuyện ',
     '人数': 'Số người ',
     '创建': 'Tạo ra ',
     '房间名不能为空': 'Tên phòng chat không được để trống ',
     '聊天室邀请': 'Lời mời',
     '邀请': 'Mời ',
     '暂无名称': 'Hiện không có tên ',
     '距离': 'Khoảng cách ',
     '准备': 'Sẵn sàng ',
     '邀请朋友': 'Mời bạn ',
     '米': 'Mét',
     '暂无房间id': 'Hiện không có ID phòng ',
     '我的聊天室': 'Phòng cuả tôi ',
     '购买钥匙': 'Mua chìa khóa ',
     '准备中': 'Đang chuẩn bị ',
     '进行中': 'Đang tiến hành ',
     '待评价': 'Đợi đánh giá ',
     '待结算': 'Đợi kết toán ',
     '已结束': 'Đã kết thúc ',
     '暂未创建或加入聊天室': 'Hiện chưa tạo hoặc tham gia phòng trò chuyện',
     '推荐聊天室': 'Đề xuất phòng trò chuyện',
     '头像': 'Hình đại diện ',
     '这个聊天室没有名称': 'Phòng trò chuyện này không có tên ',
     '申请': 'Đệ trình ',
     '已加入聊天室': 'Đã tham gia phòng trò chuyện',
     '等待其他玩家评价': 'Đang chờ người chơi khác đánh giá',
     '您还没有创建房间': 'Bạn vẫn chưa tạo phòng',
     '开始挖矿': 'Bắt đầu khai thác ',
     '退出房间': 'Thoát khỏi phòng ',
     '复制链接': 'Sao chép liên kết ',
     '发送邀请': 'Gửi yêu cầu ',
     '提示': 'Lời nhắc ',
     '请确认是否退出聊天室': 'Vui lòng xác nhận có thoát khỏi phòng trò chuyện hay không',
     '请输入邀请链接': 'Vui lòng nhập đường link mời',
     '加入聊天室': 'tham gia vào',
     '在挖矿中': 'Đang khai thác ',
     '定时进程': 'Đếm ngược ',
     '完成挖矿': 'Hoàn thành',
     '停止聊天室': 'Dừng phòng trò chuyện ',
     '在线玩家': 'Người chơi trực tuyến ',
     '请确认是否停止聊天，此操作将会退出聊天室并且不会获得收益': 'Vui lòng xác nhận xem có dừng trò chuyện hay không, thao tác này sẽ thoát khỏi phòng trò chuyện và sẽ không nhận được bất kỳ thu nhập nào',
     '社交聊天室': 'phòng chat',
     '创建聊天室': 'tạo phòng',
     '邀请链接': 'Đường link mời ',
     '有新的请求，请在请求弹窗中查看': 'Có một yêu cầu mới, vui lòng kiểm tra trong cửa sổ yêu cầu ',
     '有新的邀请，请在邀请弹窗中查看': 'Có một lời mời mới  , vui lòng kiểm tra trong cửa sổ mời ',
     '房主已拒绝': 'Chủ phòng đã từ chối ',
     '用户拒绝加入': 'Người dùng từ chối tham gia',
     '挖矿收益': 'Thu nhập khai thác ',
     '我的评分': 'Xếp hạng của tôi',
     '奖励分配': 'Phân bổ thưởng ',
     '评分': 'Xếp hạng ',
     '社交评分': 'Xệp hạng cộng đồng ',
     '提交': 'Gửi',
     "暂无计划":"Hiện không có kế hoạch ",
     "活动报告":"Báo cáo hoạt động ",
     "分钟":"Phút ",
     "每日":"Mỗi ngày ",
     "每月":"Mỗi tháng ",
     "获得":"Nhận được ",
     "总支出":"Tổng chi ra ",
     "点 击 关 闭":"Nhấp để đóng ",
     "朋友帮助请求":"Yêu cầu hỗ trợ bạn bè ",
     "发送帮助":"Gửi hỗ trợ ",
     "推荐任务":"Đề xuất nhiệm vụ ",
     "描述":"Mô tả ",
     "接受":"Nhận",
     "待完成":"Đợi hoàn thành ",
     "特别活动":"Hoạt động đặc biệt ",
     "特别":"Đặc biệt ",
     '此操作会将房间解散，并且不会退还钥匙': 'Thao tác này sẽ giải tán căn phòng và chìa khóa sẽ không được trả lại',
     '运动收益': 'Phần thưởng thể thao',
     '社交收益': 'Phần thưởng xã hội',
     '累计时长(分钟)': 'Thời gian tích lũy (phút)',
     '实时运动': 'Hẹn giờ',
     '领取': 'Nhận',
     '交易成功后等级 + 1': 'Cấp độ + 1 sau khi giao dịch thành công',
     '信息更新中...': 'Thông tin đang được cập nhật...',
     '连接钱包中...': 'Đang kết nối ví...',
     '解散房间': 'Phòng giải tán',
     '未结算': 'Không ổn định',
     '已结算': 'Định cư',
     '日期选择': 'Chọn ngày',
     '未领取': 'Chưa nhận ',
     '已领取': 'Đã kết toán',
     '已升级的nft数量': 'Số lượng nft đã nâng cấp',
     '请选择修复次数':'Vui lòng chọn số lần khôi phục ',
     '暂无nft可领取':'Tạm thời không có nft nào có thể nhận',
     '暂无团队收益可领取':'Tạm thời không có thu nhập đội nhóm có thể nhận',
     '团队收益':'Thu nhập đội nhóm',
     '当前修复分数': 'Số điểm sửa đổi hiện tại',
     '请输入密码': 'Vui lòng nhập mật khẩu',
     '设置密码': 'Cài đặt mật khẩu',
     '密码': 'Mật khẩu',
     '再次确认': 'Xác nhận lại',
     '请再次输入密码': 'Vui lòng nhập lại mật khẩu',
     '两次输入的密码不一致': 'Mật khẩu nhập hai lần không trùng khớp',
     '未激活': 'Chưa kích hoạt ',
     '已激活': 'Đã kích hoạt ',
     '成功': 'Thành công',
     '失败': 'Thất bại ',
     '请确认是否将此用户请离房间': 'Vui lòng xác nhận xem có xóa người dùng này khỏi phòng hay không',
     '收藏奖励': 'Giải thưởng bộ sưu tập',
     '教程': 'Giáo trình',
     '请选择类型': 'Vui lòng chọn loại',
     '评分(人气)': 'Xếp hạng',
     '日榜': 'Bảng ngày',
     '月榜': 'Bảng tháng',
     '总榜': 'Toàn Bộ',
     '升级进度': 'Tiến độ nâng cấp',
     '暂无收益可领取': 'Tạm thời không có lợi nhuận có thể lĩnh',
     '价格需要大于5000': 'Giá phải lớn hơn 5000',
     '团队NFT升级奖励': 'Thu nhập nâng cấp NFT',
     '名称': 'Tên',
     '钱包地址': 'Địa chỉ ví',
     '设置昵称': 'Đặt biệt danh',
     '昵称': 'Biệt danh',
     '请输入昵称': 'Vui lòng nhập nickname',
     '昵称不能为空': 'Biệt danh không được để trống',
     '请先选择头像': 'Vui lòng chọn avatar trước',
     '设置个人信息': 'Thiết lập thông tin cá nhân',
     '数量': 'Số lượng',
     '选择钥匙数量': 'Chọn số key',
     '房间人数需要大于2,才能正常退出': 'Số lượng phòng cần lớn hơn 2 để thoát ra bình thường.',
     '价格需要大于50000': 'Giá cần lớn hơn 50.000',
     '是否确认绑定': 'Bạn có xác nhận ràng buộc không?',
     '上级名称': 'Tên cấp trên：',
     '上级邀请码': 'Mã giới thiệu của cấp trên：',
     '上级地址': 'Địa chỉ cấp trên：',
     '请通过新的邀请链接进入': 'Vui lòng nhập thông qua liên kết mời mới',
     '群组/聊天': '群组/聊天',
     '此卡已是最高等级': 'NFT này đã ở cấp độ cao nhất',
     '领取中': 'Đang nhận',
     '不可领取': 'Không thể nhận',
     '领取成功': 'Nhận thành công',
     '已卖出': 'Đã bán',
     '已升级': 'Đã nâng cấp',
     '请先选择房间': 'Vui lòng chọn phòng trước',
     '网络不好,请稍后再试': 'Kết nối mạng kém, vui lòng thử lại sau',
     '购买钥匙授权': 'Mua quyền ủy quyền khóa',
     '购买评分授权': 'Mua quyền đánh giá',
     '修复耐久授权': 'Ủy quyền sửa chữa độ bền',
     '升级授权': 'Ủy quyền nâng cấp',
     '挂卖补偿奖励': 'Phần thưởng bồi thường khi niêm yết',
     '进入聊天室': 'Vào phòng trò chuyện xã hội',
     '*请在1小时结束社交后进行评分': '*Vui lòng đánh giá sau 1 giờ kết thúc giao tiếp xã hội',
     '确认分享': 'Xác nhận chia sẻ',
     '账号未激活，是否分享邀请链接?': 'Tài khoản chưa được kích hoạt, bạn có muốn chia sẻ liên kết mời không?',
     '绑定上级中,链上交易需要时间,请耐心等待几分钟再去操作...': 'Đang liên kết với cấp trên, giao dịch trên chuỗi cần thời gian, vui lòng đợi vài phút trước khi tiến hành kích hoạt...',
     '绑定成功': 'Liên kết thành công',
     '绑定失败': 'Liên kết thất bại',
     '链上已经绑定上级,交易确认中,请稍后再试': 'Đã ràng buộc cấp trên trên chuỗi, đang xác nhận giao dịch, vui lòng thử lại sau',
     '登录已过期,请重新登录': 'Phiên đăng nhập đã hết hạn, vui lòng đăng nhập lại.',
     '确认购买': 'Xác nhận Mua hàng',
     '购买钥匙当次所需数量为:': 'Số lượng cần thiết để mua chìa khóa:\n',
     '购买评分当次所需数量为:': 'Số lượng cần thiết để mua xếp hạng:\n',
     '确认修复': 'Xác nhận Sửa chữa',
     '修复耐久当次所需数量为:': 'Số lượng cần thiết để sửa chữa độ bền:\n',
     '确认升级': 'Xác nhận nâng cấp',
     '升级NFT当次所需数量为:': 'Số lượng cần thiết để nâng cấp NFT là\n',
}
import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
import cn from './cn'
import en from './en'
import vi from './vi'
import ko from './ko'
import id from './id'
import ms from './ms'
let locale = localStorage.getItem('langue') || '';
if( locale == '' ){
	locale = 'en';
	localStorage.setItem('langue',locale)
}
const i18n = new VueI18n({
	locale: locale,
	messages: {
		cn,
		en,
		vi,
		ko,
		id,
		ms,
	},
	fallbackLocale: 'en',
	silentTranslationWarn: true, // 隐藏警告
	formatFallbackMessages: true
})

// 非 vue 文件中使用这个方法
export const translate = (localeKey) => {
	const hasKey = i18n.te(localeKey, locale) // 使用i18n的 te 方法来检查是否能够匹配到对应键值
	const translatedStr = i18n.t(localeKey) 
	if (hasKey) {
		return translatedStr
	}
	return localeKey
}

export default i18n